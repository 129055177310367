import { useNavigate } from 'react-router-dom';

import "./page.css";
import { useEffect } from 'react';

const LLC = () => {

  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <div className="banner-title">Main U.S. Entity Types</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <h3>Partnerships</h3>
          <p>
            Partnerships are formed when two or more individuals collaborate in a trade or business. Each partner shares
            liability for the partnership's actions, including those of other partners, conducted within the business scope.
            For tax purposes, partnerships are "pass-through" entities, meaning each partner pays taxes on their share of
            partnership gains while also bearing responsibility for any losses. Each partner files their own tax return.
          </p>
          <h3>Limited Liability Company (LLC)</h3>
          <p>
            LLCs are created by filing Articles of Organization with the appropriate state. Like partnerships, LLCs LLCs are
            created by filing Articles of Organization with the appropriate state. Like partnerships, LLCs are pass-through
            entities for tax purposes. However, LLC members enjoy limited personal liability, protecting their personal assets
            from business debts and obligations. are pass-through entities for tax purposes. However, LLC members enjoy limited
            personal liability, protecting their personal assets from business debts and obligations.
          </p>
          <h3>Corporation</h3>
          <p>
            Corporations are established by filing incorporation documents in a selected U.S. state. Like LLCs, corporations
            are distinct legal entities that protect owners from personal liability. However, they differ significantly in
            taxation. Corporations are not pass-through entities; they pay taxes on profits at the corporate level and are
            subject to double taxation—once on profits and again on dividends distributed to owners.
          </p>
        </div>
        <div className="col">
          <div className="mb-4 pb-4">
            <div className="tags-sub-div active">Main U.S. Entity Types</div>
            <div className="tags-sub-div" onClick={() => handleClick('/corporate-taxation')}>Corporate Taxation</div>
            <div className="tags-sub-div" onClick={() => handleClick('/accounting-and-bookkeeping')}>Accounting And Bookkeeping</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LLC;