import { useEffect } from "react";
import "./page.css";

const Testimonial = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div>
      <div className="banner-title about-us" />
      <div className="llc-div row">
        <div className="col-md-12">
          <p>
            Space Tax Filing is an Indian-origin organisation in Dallas, USA, specialising in tax services. Our professionals
            excel at quality analysis, quality assurance, and building strong client relationships. Our objective is to
            completely understand the client's current tax situations and historical tax histories so that we may apply our
            experience and produce risk-free results while strictly complying with all IRS laws and regulations, including IRS
            Circular 230 and ASC 740.
          </p>
          <h2>Mission:</h2>
          <p>Redefining tax consultation in the USA, our goal is more than just filing your taxes. We provide strategic analysis,
            expert advice, and ensure that every taxpayer reclaims the money they rightfully deserve. By combining advanced
            technology with professional expertise, we bring transparency, precision, and complete trust to the tax filing
            experience.
          </p>
          <h2>Vision:</h2>
          <p>
            Our vision is to be the industry leader in US taxation services, recognized for our unwavering commitment to client
            success and excellence. By pursuing this vision, we aim to be the go-to resource for US taxation, where clients not
            only meet their tax obligations but also thrive financially. Together, we'll shape a brighter financial future.
          </p>
        </div>
      </div>
    </div>
  )

}

export default Testimonial;