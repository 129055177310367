import { useNavigate } from 'react-router-dom';

import "./page.css";
import { useEffect } from 'react';

const AccountService = () => {

  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <div className="banner-title">Accounting And Bookkeeping</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <p>
            Space Tax delivers exceptional bookkeeping services characterized by quality and precision. Our skilled bookkeeping
            team adapts quickly to the specific needs of our clients while ensuring timely and accurate service. With extensive
            experience, we handle all your bookkeeping requirements with the utmost care and efficiency.
          </p>
          <h3>Our bookkeeping services include:</h3>
          <ul className="career-ul">
            <li>QuickBooks Setup and Training</li>
            <li>Accounts Receivable and Payable</li>
            <li>Bank, Credit Card, and Asset Reconciliation</li>
            <li>Financial Statement Preparation</li>
            <li>Management Reporting</li>
            <li>Part-Time CFO Services</li>
          </ul>
        </div>
        <div className="col">
          <div className="mb-4 pb-4">
            <div className="tags-sub-div" onClick={() => handleClick('/main-types')}>Main U.S. Entity Types</div>
            <div className="tags-sub-div" onClick={() => handleClick('/corporate-taxation')}>Corporate Taxation</div>
            <div className="tags-sub-div active">Accounting And Bookkeeping</div>
          </div>
        </div>
      </div >
    </>
  )
}

export default AccountService;