import { useEffect } from "react";
import "./page.css";

const Contact = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div>
      <div className="banner-title contact_us" />
      <div className="contact-us-div row">
        <div className="contact-us-form row">
          <h3>Request a call</h3>
          <div className="col-md-6">
            <input className="form-control" placeholder="Your Name*" />
          </div>
          <div className="col-md-6">
            <input className="form-control" placeholder="Your Mail*" />
          </div>
          <div className="col-md-6">
            <input className="form-control" placeholder="Phone*" />
          </div>
          <div className="col-md-6">
            <input className="form-control" placeholder="Subject*" />
          </div>
          <div className="col-md-6">
            <textarea className="form-control" placeholder="Your Message....*" />
          </div>
          <div className="col-md-12">
            <button className="form-control">SEND MESSAGE</button>
          </div>
        </div>
        <div className="col mt-4">
          <div className="row contact-divs">
            <div className="col">
              <i class="fa fa-address-card-o"></i>
              <h3>Location</h3>
              <p>8100 Gaylord Pkwy, Apt 1254, Frisco Texas 75034</p>
            </div>
            <div className="col offset-md-1">
              <i class="fa fa-whatsapp" />
              <h3>Contact</h3>
              <p>+91 799-522-8998</p>
            </div>
            <div className="col  offset-md-1">
              <i class="fa fa-envelope" />
              <h3>Email</h3>
              <p>support@spacetaxfiling.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact;