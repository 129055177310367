import "./page.css";

const Terms = () => {

  return (
    <>
      <div className="banner-title">Terms and Conditions</div>
      <div className="terms-div">
        <h2>Terms and Conditions</h2>
        <p>
          We Thank you showing trust on us. Request you to please go through the terms and conditions. These terms are relation
          to our services through our branch Besttaxfiler llc. Please review the terms and conditions and proceed further. Review
          these periodically and if you are not accepting any of these terms and conditions unacceptable request not to proceed
          further and let us know. Btf global has right to revise the terms at any point without prior intimation. We after
          receiving the information for preparing the tax return prepare the tax return based on the information provided by
          you,we does not hold any responsibility in verification or audit of information provided by you.
        </p>
        <p>
          Based on the information we prepare tax returns and see if any planning is applicable on the tax returns and if
          required will inform you the fee for it, based on your interest availing the service is up to you, If you wish to
          proceed ahead you need to make the payment which is mentioned in your login.
        </p>
        <p>
          Once the payment is made we send you the tax return draft copy, here you can see your tax return copy prepared. We
          shall refund the fee based on client request prior issuing the tax return draft copy.
        </p>
        <p>
          Cancelation policy:
        </p>
        <p>
          We reserves the right, in its sole discretion, to suspend or cancel the service at any time if a computer virus, bug,
          or other technical problem corrupts the security, or proper administration of the service. Prior giving the acceptance
          to file the tax return you are eligible to cancel the service at any point of time.
        </p>
        <p>
          Refund policy:
        </p>
        <p>
          for any reason if you don’t wish to avail the service after uploading the draft tax if refund request is raised there
          will be minimum fee $99.99 for preparation charges and the rest of the amount is refunded.
        </p>
        <p>
          Privacy Policy:
        </p>
        <p>
          We does not disclose any personal information to advertisers and for other marketing and promotional purposes that
          could be used to personally identify you, such as your password, credit card number and bank account number.
        </p>
        <p>
          Yupp Tv code will be issued only on receipt of full fee without any discount..
        </p>
      </div>
    </>
  )
}

export default Terms;