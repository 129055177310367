import "./page.css";

import Services from "../Segments/Services/Services";

const PayrollService = () => {

  return (
    <>
      <div className="banner-title">Tax Preparation</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <p>
            Tax preparation is the process of organizing and filing your tax returns to comply with federal and state tax laws. Whether
            you are an individual or a business, proper tax preparation can help you maximize deductions, minimize liabilities, and
            avoid potential penalties. This guide provides essential steps and tips to streamline your tax preparation process
          </p>
          <h3>Steps for Effective Tax Preparation</h3>
          <ul className="career-ul">
            <li>Gather Your Documents</li>
            <li>Choose the Right Tax Forms</li>
            <li>Determine Filing Status</li>
            <li>File Your Tax Return</li>
            <li>Pay Any Taxes Owed</li>
          </ul>
          <p>
            Maintain copies of your tax return and all supporting documents for at least three years.
          </p>
        </div>
        <div className="col">
          <Services />
        </div>
      </div >
    </>
  )
}

export default PayrollService;