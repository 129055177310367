import "./page.css";

import Services from "../Segments/Services/Services";

const OtherService = () => {

  return (
    <>
      <div className="banner-title">Business Tax Filing</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <p>
            Filing taxes for a business involves several steps and requires careful attention to detail. Whether you operate
            as a sole proprietorship, partnership, corporation, or another entity, understanding the tax filing requirements
            and deadlines is essential. Space Tax Filing provides an overview of business tax filing, including necessary forms,
            deadlines, and tips to streamline the process.
          </p>
          <ul className="career-ul">
            <li>Sole Proprietorship</li>
            <li>Partnership</li>
            <li>C- Corporation</li>
            <li>S - Corporation</li>
            <li>Limited Liability Company (LLC)</li>
          </ul>
        </div>
        <div className="col">
          <Services />
        </div>
      </div >
    </>
  )
}

export default OtherService;