import "./page.css";

import Services from "../Segments/Services/Services";

const ServicesAmendment = () => {

  return (
    <>
      <div className="banner-title">ITIN Processing</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <p>
            An <b>Individual Taxpayer Identification Number (ITIN)</b> is a tax processing number issued by the <b>Internal Revenue
              Service (IRS)</b> for individuals who are not eligible to obtain a <b>Social Security Number (SSN)</b> but need to file tax
            returns or other tax-related documents. <b>Space Tax Filing</b> provides an overview of the <b>ITIN</b> application process,
            requirements, and tips to ensure a smooth application. As per the rules of the <b>IRS.</b>
          </p>
          <p>
            If your <b>ITIN</b> is expiring or has expired, you will need to renew it before filing your next tax return.
          </p>
        </div>
        <div className="col">
          <Services />
        </div>
      </div >
    </>
  )
}

export default ServicesAmendment;