import "./footer.css";
import Logo from "../../public/images/spacing-logo.png";

import { useNavigate } from 'react-router-dom'
import { useState } from "react";

const Footer = () => {

  const [accData] = useState([
    { header: "Home", url: "/" },
    { header: "About Us", url: "/about-us" },
    { header: "Refund Status", child: [], url: "/refund-status" },
    { header: "Contact", child: [], url: "/contact" },
    { header: "Privacy and Policy", child: [], url: "/privacy-policy" },
    { header: "Sign Up", child: [], url: "/sign-up" },
  ])

  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route);
  };

  return (
    <div className="footer_main_div row">
      <div className="col-md-5">
        <div><img width="80%" src={Logo} style={{padding: '10px 20px', margin: '0 30px'}} /></div>
        <p>
          Space Tax Filing specializes in providing seamless tax filing services, ensuring compliance with
          federal and state regulations while maximizing refunds and minimizing liabilities. With expert
          guidance, secure technology, and personalized support, we make tax filing easy, efficient, and
          stress-free for individuals and businesses
        </p>
      </div>
      <div className="col offset-md-1">
        <h2>QUICK LINKS</h2>
        {
          accData.map(ql => {
            return <p onClick={() => handleClick(ql.url)}>{ql.header}</p>
          })
        }
      </div>
      <div className="col offset-md-1">
        <h2>CONTACT</h2>
        <p>US: +1 469-225-3546</p>
        <p>IND: +91 799-522-8998</p>
        <p>support@spacetaxfiling.com</p>
        <p>8100 Gaylord Pkwy</p>
        <p>Apt 1254, Frisco Texas 75034.</p>
        <i className="fa fa-facebook" />
        <i className="fa fa-linkedin" />
        <i className="fa fa-pinterest" />
        <i className="fa fa-instagram" />
      </div>
    </div>
  )
}

export default Footer;
