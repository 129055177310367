import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

const Services = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const navigate = useNavigate();
  const [data] = useState([
    { name: "Advance Tax Planning >>", route: "/advance-tax-planning" },
    { name: "Tax Preparation >>", route: "/tax-preparation" },
    { name: "Audit Assistance >>", route:"/audit-assistance"},
    { name: "Extension Filing >>", route: "/extension-filing" },
    { name: "ITIN Processing >>", route: "/itin-processing" },
    { name: "FBAR and FATCA >>", route: "/fbar-and-fatca" },
    { name: "Business Tax Filing >>", route: "/business-tax-filing" },
    { name: "Indian Taxes >>", route: "/indian-taxes" }
  ])

  const handleClick = (route) => {
    navigate(route);
  };

  return <>
    <div className="mb-4 pb-4">
      {
        data.map(d => {
          return <div onClick={() => handleClick(d.route)} className="tags-sub-div">{d.name}</div>
        })
      }
    </div>
  </>

}

export default Services;