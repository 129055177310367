import "./page.css";

import Services from "../Segments/Services/Services";

const ITIN = () => {

  return (
    <>
      <div className="banner-title">Audit Assistance</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <p>
            An audit by the Internal Revenue Service (IRS) can be a daunting experience, but with proper preparation and
            understanding, you can navigate the process smoothly with STF. SPACE TAX FILING provides essential information
            and steps to help you manage an IRS audit effectively. An IRS audit is a review of your financial information and
            accounts to ensure that you have reported your income, deductions, and credits accurately. Audits can be conducted
            by mail or in person and can range from simple correspondence audits to more complex field audits.
          </p>
        </div>
        <div className="col">
          <Services />
        </div>
      </div >
    </>
  )
}

export default ITIN;