import "./page.css";

import Services from "../Segments/Services/Services";

const TaxRepresentation = () => {

  return (
    <>
      <div className="banner-title">Extension Filing</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <p>
            Filing an extension for your tax return can provide you with extra time to gather necessary documents, organize
            your financial information and ensure your return is accurate. SPACE TAX FILING outlines the process and benefits
            of filing a tax extension, along with important deadlines and tips.
          </p>
          <p>
            You receive an additional six months to prepare and file your return.
          </p>
        </div>
        <div className="col">
          <Services />
        </div>
      </div >
    </>
  )
}

export default TaxRepresentation;