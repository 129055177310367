import { useNavigate } from 'react-router-dom';

import "./page.css";
import { useEffect } from 'react';

const CCR = () => {

  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <div className="banner-title">Corporate Taxation</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <p>
            Space Tax offers comprehensive taxation services tailored to the corporate sector across the United States. Our
            diverse client base includes multinational corporations, privately-owned businesses, small local enterprises, and
            not-for-profit organisations.
          </p>
          <h3>We provide strategic tax preparation and planning services for:</h3>
          <ul>
            <li>Corporate Returns (1120, 1120S, etc.)</li>
            <li>Partnership Returns (LLC, LLP, LP, etc.)</li>
            <li>Exempt Organizations (Form 990)</li>
            <li>Fiduciary Tax Returns (Form 1041)</li>
            <li>Sales Tax Returns</li>
            <li>Property Tax Returns</li>
            <li>Payroll Tax Returns</li>
            <li>Book/Tax Difference Computation</li>
          </ul>
        </div>
        <div className="col">
          <div className="mb-4 pb-4">
            <div className="tags-sub-div" onClick={() => handleClick('/main-types')}>Main U.S. Entity Types</div>
            <div className="tags-sub-div active">Corporate Taxation</div>
            <div className="tags-sub-div" onClick={() => handleClick('/accounting-and-bookkeeping')}>Accounting And Bookkeeping</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CCR;