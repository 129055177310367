import { useEffect, useState } from "react"
import "./page.css";

const RefundStatus = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [data] = useState([
    { label: "Federal refund status:", link: "https://sa.www4.irs.gov/irfof/lang/en/irfofgetstatus.jsp" },
    { label: "Pay Federal taxes online:", link: "https://www.irs.gov/payments/pay-taxes-by-credit-or-debit-card" },
    { label: "Pay state taxes online:", link: "https://www.officialpayments.com/index.jsp" },
    { label: "State refund Status", link: "http://www.statetaxinformation.com/stateinfo.aspx" },
  ]);

  return (<>
    <div className="banner-title refund_status" />
    <h1 style={{ color: "#000", textAlign: "center" }} className="mt-4 mb-4">Refund Status</h1>
    <div className="price-div row mb-4 pb-4">
      {
        data.map(d => {
          return (
            <div className="refund-status col-md-6">
              <h3>{d.label}</h3>
              <a href={d.link}>{d.link}</a>
            </div>
          )
        })
      }
    </div>
  </>)
}

export default RefundStatus;