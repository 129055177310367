import "./page.css";

import Services from "../Segments/Services/Services";

import { useState } from "react";


const FinansialPlanning = () => {

  const [leftLis] = useState([
    "Retirement Planning",
    "Business Planning",
    "Estate Conservation Strategies",
    "Find and address performance issues",
    "Fixed and Variable Annuities",
    "Variable and Traditional Life Insurance",
    "Disability Income and Other Insurance",
    "Long-Term Care Insurance",
    "401(k) and other Company Sponsored Plans",
    "403(b) Plans",
    "Asset Management Accounts"
  ])

  const [rightLis] = useState([
    "Mutual Funds",
    "Money Markets",
    "Traditional and Roth IRAs",
    "Wrap Fee Accounts",
    "Brokerage Accounts",
    "529 Plans",
    "Deferred Compensation Programs",
    "Buy-Sell Plans",
    "Non-Qualified Retirement Plans",
    "Life Insurance for Key Persons",
    "Asset Allocation Strategies"
  ])

  return (
    <>
      <div className="banner-title">Our Services</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <h2>Financial Planning Services</h2>
          <p>
            Our firm’s collective capabilities and experience – together with our team approach – allows us to assess and
            offer you a wide-range of financial products and services.
          </p>
          <p>
            Best Tax Filer provides Individuals, Small Business & Corporate Tax Service. Best Tax Filer is the online Tax
            Consultant where can get everything under one roof over a phone call. Best Tax Filer provides the Tax
            service/Suggestions throughout the year, An experience Tax Analyst will be available to help or suggest the
            taxpayer on the below query’s.
          </p>
          <p className="pb-4 mb-4">
            The keys to our firm’s success are our professional excellence and integrity, and our constant pursuit of open
            communication and an in-depth understanding of each of our client’s unique financial needs. Our associates’
            credentials, experience and principled approach allow us to deliver on our promise of personalized service and
            provide an added level of comfort and confidence for our clients.
          </p>
          <p className="mt-4 mt-4">
            We look forward to helping you take control of your financial future.
          </p>
          <p>
            AXA Advisors Financial Professionals can offer a variety of financial products and services that are designed
            to help you plan for, build and achieve a more stable financial future. These includ _e:
          </p>
          <div className="row mb-4">
            <div className="col">
              <ul style={{ listStyle: "square" }}>
                {
                  leftLis.map(l => {
                    return <li>{l}</li>
                  })
                }
              </ul>
            </div>
            <div className="col">
              <ul style={{ listStyle: "square" }}>
                {
                  rightLis.map(l => {
                    return <li>{l}</li>
                  })
                }
              </ul>
            </div>
          </div>
        </div>
        <div className="col">
          <Services />
        </div>
      </div >
    </>
  )
}

export default FinansialPlanning;