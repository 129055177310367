import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'

import "../Segments/Header/header.css";

import Banner1 from "../public/images/banner_1.jpg";
import Banner2 from "../public/images/banner_2.jpg";
import Banner3 from "../public/images/banner_3.jpg";

import Steps from "../public/images/steps.jpg";
import CORP from "../public/images/stf_differs_was_image.jpg";
import IndianTaxes from  "../public/images/indian_taxes.png";
import CorporateServices from  "../public/images/corporate_services.png";
import AdvanceTaxPlanning from  "../public/images/advance_tax_planning.jpg";
import AuditAssistance from  "../public/images/audit_assistance.jpg";
import BusinessTaxFiling from  "../public/images/business_tax_filing.png";
import ExtensionFiling from  "../public/images/extension_filing.jpg";
import FBARFATCA from  "../public/images/FBAR_FATCA.png";
import ITINProcessing from  "../public/images/ITIN_processing.jpg";
import TaxPreparation from "../public/images/tax_preparation.jpg";

const Home = () => {

	const navigate = useNavigate();

	const [currentIndex, setCurrentIndex] = useState(0);

	const [slides] = useState([
		{ id: 1, image: Banner1, title: 'Start early get your', content: 'Refund Early' },
		{ id: 2, image: Banner2, title: 'Get e-file', content: 'Avoid paper work' },
		{ id: 3, image: Banner3, title: 'Preview your completed returns for free', content: 'Pay nothing untill you file' }
	]);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
		}, 3000);

		return () => clearInterval(interval);
	}, [slides.length]);
	const goToNextSlide = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
	};

	const goToPrevSlide = () => {
		setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
	};

	const handleClick = (route) => {
		navigate(route);
	};

	return (
		<div>
			<section className="hero_slider">
				<div className="slider">
					<button className="arrow prev" onClick={goToPrevSlide}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="50" height="50">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
						</svg>
					</button>
					{slides.map((slide, index) => (
						<div
							key={slide.id} className={`slide ${index === currentIndex ? 'active' : ''}`}
							style={{ backgroundImage: `url(${slide.image})` }}
						>
						</div>
					))}
					<button className="arrow next" onClick={goToNextSlide}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="50" height="50">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
						</svg>
					</button>
				</div>
			</section>
			<section className="feature-box-sec">
				<div className="container-fluid">
					<div className="row pl-0 pr-0 welcome-text">
						<h2 className="mt-2">WELCOME TO <b>SPACE TAX FILING</b></h2>
						<p>
							Space Tax Filing is an Indian-origin organization in Dallas, USA, that specializes in tax services.
							Our professionals excel at quality analysis, quality assurance, and building strong client
							relationships.Our objective is to completely understand the client's current tax situations and
							historical tax histories so that we may apply our experience and produce risk-free results while
							strictly complying with all IRS laws and regulations, including IRS Circular 230 and ASC 740.
						</p>
						<p>
							Our educated personnel at Space Tax Filing are experienced in dealing with all tax concerns.
							We operate with Desire, Dedication, Determination, and Discipline to provide the greatest service.
							Our objective is to be your trusted partner in negotiating the complexities of US taxation. We are
							committed to assisting you in lowering your tax payments, increasing your financial potential, and
							achieving tax peace of mind.
						</p>
						<p onClick={() => navigate('/about-us')} className="read-more">read more</p>
					</div>
				</div>
			</section>
			<section className="our-services-sec">
				<div className="container">
					<div className="row image-over-div">
						<div className="heading col-12">
							<h2 className="xs-title section-title"><span className="shortheading">Our Services</span></h2>
						</div>
						<div className="col-4">
							<div className="service-overlay">
								<div className="image-wrapper">
									<img width="320" height="250" src={AdvanceTaxPlanning} />
								</div>
								<div className="service-content">
									<h3 className="service-title advance-tax-planning">
										<span className="service-icon">
											<i className="fa fa-pie-chart"></i>
										</span>
										Advance Tax Planning
									</h3>
									<p>Advance tax planning is a proactive approach to managing ...</p>
									<p><a className="btn btn-primary icon-bg" onClick={() => handleClick('/advance-tax-planning')}>
										Read More
										<i className="fa fa-arrow-right"></i></a>
									</p>
								</div>
							</div>
						</div>
						<div className="col-4">
							<div className="service-overlay">
								<div className="image-wrapper">
									<img width="320" height="250" src={TaxPreparation} />
								</div>
								<div className="service-content">
									<h3 className="service-title">
										<span className="service-icon">
											<i className="fa fa-pie-chart"></i>
										</span>
										Tax Preparation
									</h3>
									<p>Tax preparation is the process of organizing and filing your tax returns ...</p>
									<p><a className="btn btn-primary icon-bg" onClick={() => handleClick('/tax-preparation')}>
										Read More
										<i className="fa fa-arrow-right"></i></a>
									</p>
								</div>
							</div>
						</div>
						<div className="col-4">
							<div className="service-overlay">
								<div className="image-wrapper">
									<img width="320" height="250" src={AuditAssistance} />
								</div>
								<div className="service-content">
									<h3 className="service-title">
										<span className="service-icon">
											<i className="fa fa-pie-chart"></i>
										</span>
										Audit Assistance
									</h3>
									<p>An audit by the Internal Revenue Service (IRS) can be a daunting experience, but with ...</p>
									<p><a className="btn btn-primary icon-bg" onClick={() => handleClick('/audit-assistance')}>
										Read More
										<i className="fa fa-arrow-right"></i></a>
									</p>
								</div>
							</div>
						</div>
						<div className="col-4">
							<div className="service-overlay">
								<div className="image-wrapper">
									<img width="320" height="250" src={ExtensionFiling} />
								</div>
								<div className="service-content">
									<h3 className="service-title">
										<span className="service-icon">
											<i className="fa fa-pie-chart"></i>
										</span>
										Extension Filing
									</h3>
									<p>Filing an extension for your tax return can provide you with extra time to gather ...</p>
									<p><a className="btn btn-primary icon-bg" onClick={() => handleClick('/extension-filing')}>
										Read More
										<i className="fa fa-arrow-right"></i></a>
									</p>
								</div>
							</div>
						</div>
						<div className="col-4">
							<div className="service-overlay">
								<div className="image-wrapper">
									<img width="320" height="250" src={ITINProcessing} />
								</div>
								<div className="service-content">
									<h3 className="service-title">
										<span className="service-icon">
											<i className="fa fa-pie-chart"></i>
										</span>
										ITIN Processing
									</h3>
									<p>An Individual Taxpayer Identification Number (ITIN) is a tax processing number ...</p>
									<p><a className="btn btn-primary icon-bg" onClick={() => handleClick('/itin-processing')}>
										Read More
										<i className="fa fa-arrow-right"></i></a>
									</p>
								</div>
							</div>
						</div>
						<div className="col-4">
							<div className="service-overlay">
								<div className="image-wrapper">
									<img width="320" height="250" src={FBARFATCA} />
								</div>
								<div className="service-content">
									<h3 className="service-title">
										<span className="service-icon">
											<i className="fa fa-pie-chart"></i>
										</span>
										FBAR And FATCA
									</h3>
									<p>The Foreign Bank Account Report (FBAR) and the Foreign Account Tax Compliance Act ...</p>
									<p><a className="btn btn-primary icon-bg" onClick={() => handleClick('/fbar-and-fatca')}>
										Read More
										<i className="fa fa-arrow-right"></i></a>
									</p>
								</div>
							</div>
						</div>
						<div className="col-4">
							<div className="service-overlay">
								<div className="image-wrapper">
									<img width="320" height="250" src={BusinessTaxFiling} />
								</div>
								<div className="service-content">
									<h3 className="service-title">
										<span className="service-icon">
											<i className="fa fa-pie-chart"></i>
										</span>
										Business Tax Filling
									</h3>
									<p>Filing taxes for a business involves several steps and requires ...</p>
									<p><a className="btn btn-primary icon-bg" onClick={() => handleClick('/business-tax-filing')}>
										Read More
										<i className="fa fa-arrow-right"></i></a>
									</p>
								</div>
							</div>
						</div>
						<div className="col-4">
							<div className="service-overlay">
								<div className="image-wrapper">
									<img width="320" height="250" src={IndianTaxes} />
								</div>
								<div className="service-content">
									<h3 className="service-title">
										<span className="service-icon">
											<i className="fa fa-pie-chart"></i>
										</span>
										Indian Taxes
									</h3>
									<p>Space Tax operates a processing center in India that assists its US ...</p>
									<p><a className="btn btn-primary icon-bg" onClick={() => handleClick('/indian-taxes')}>
										Read More
										<i className="fa fa-arrow-right"></i></a>
									</p>
								</div>
							</div>
						</div>
						<div className="col-4">
							<div className="service-overlay">
								<div className="image-wrapper">
									<img width="320" height="250" src={CorporateServices} />
								</div>
								<div className="service-content">
									<h3 className="service-title">
										<span className="service-icon">
											<i className="fa fa-pie-chart"></i>
										</span>
										Corporate Services
									</h3>
									<p>At Space Tax, we take a holistic approach to establishing business entities ...</p>
									<p><a className="btn btn-primary icon-bg" onClick={() => handleClick('/corporate-services')}>
										Read More
										<i className="fa fa-arrow-right"></i></a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="feature-box-sec">
				<div className="container-fluid">
					<div className="row pl-0 pr-0 welcome-text">
						<i><img width="100%" src={Steps} /></i>
					</div>
				</div>
			</section>
			<section className="our-services-sec">
				<div className="row corp-ui">
					<div className="col corp-image"><img width={"100%"} src={CORP} /></div>
					{/* <div className="col">
						<h4
							style={{
								color: 'black',
								fontFamily: 'sans-serif',
								fontSize: '29px',
								fontWeight: 600
							}}
						>What Differs Us to Others</h4>
						<ul>
							<li>Fee payments are only required if the free tax forecasts are met.</li>
							<li>The most economical and reasonable prices.</li>
							<li>Maximum Refund Guarantee in faster way</li>
							<li>Round-the-clock Assistance</li>
							<li>365 days of service.</li>
							<li>Free FBAR and FATCA Submission—Up to $30</li>
							<li>Free Extensions for State & Federal Filing</li>
							<li>Access to Tax Records for Life</li>
							<li>Comprehensive end-to-end data encryption</li>
							<li>Lifetime Free IRS Audit Protection</li>
							<li>We complete tasks more quickly, accurately, and reliably.</li>
							<li>We support both satisfactory output and quality assistance, as these are the only things that can advance space tax</li>
						</ul>
					</div> */}
				</div>
			</section>
			<section className="feature-box-sec">
				<div className="container-fluid">
					<div className="row pl-0 pr-0 reviews-text">
						<h2>Our Reviews</h2>
					</div>
					<div className="reviews row">
						<div className="col pb-2">
							<p>
								<span class="fa fa-star checked"></span>
								<span class="fa fa-star checked"></span>
								<span class="fa fa-star checked"></span>
								<span class="fa fa-star checked"></span>
								<span class="fa fa-star checked"></span>
							</p>
							<h6>
								Lorem Ipsum has been the industry's standard dummy text ever since the
							</h6>
							<h2>Rahul</h2>
						</div>
						<div className="col pb-2 offset-md-1">
							<p>
								<span class="fa fa-star checked"></span>
								<span class="fa fa-star checked"></span>
								<span class="fa fa-star checked"></span>
								<span class="fa fa-star checked"></span>
								<span class="fa fa-star checked"></span>
							</p>
							<h6>
								Lorem Ipsum has been the industry's standard dummy text ever since the
							</h6>
							<h2>Rahul</h2>
						</div>
						<div className="col pb-2 offset-md-1">
							<p>
								<span class="fa fa-star checked"></span>
								<span class="fa fa-star checked"></span>
								<span class="fa fa-star checked"></span>
								<span class="fa fa-star checked"></span>
								<span class="fa fa-star checked"></span>
							</p>
							<h6>
								Lorem Ipsum has been the industry's standard dummy text ever since the
							</h6>
							<h2>Rahul</h2>
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default Home;