import "./page.css";

import Services from "../Segments/Services/Services";

const Benifits = () => {

  return (
    <>
      <div className="banner-title">Our Services</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <h2>Benefits with BTF</h2>
          <ul className="career-ul">
            <li>E-filing of tax return depending on the eligibility</li>
            <li>Our staff will show you how to adjust your payroll withholding to get more money back each week. Why give the
              IRS an interest free loan for up to 16 months.
            </li>
            <li>We will show you potential deductions to limit your tax liability for next year. In addition, we will give you
              a sheet of commonly overlooked deductions to limit the following year's tax liability.
            </li>
          </ul>
        </div>
        <div className="col">
          <Services />
        </div>
      </div >
    </>
  )
}

export default Benifits;