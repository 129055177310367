import React from 'react';
import { Helmet } from 'react-helmet';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Helmet>
        <meta name="keywords" content=" US tax filing, Online tax filing, Free tax filing, Tax preparation services, File US taxes online, US tax return filing, Expert tax consultants, Tax filing for individuals, Business tax filing services, Professional tax advisors, Simplified tax filing, IRS tax filing assistance, Fast tax return processing, Affordable tax services, Trusted tax professionals" />
        <meta name="description" content="Space Tax Filing (STF) is a trusted team of experienced professionals specializing in online U.S. tax return filing. With years of expertise, we make tax preparation simple and hassle-free. Sign up now to file your U.S. tax returns for free!" />
        <title>Space Tax Filing | Best US Tax Consulting Services | Prepare and File US Taxes Free Online– Sign Up Now.</title>
      </Helmet>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
