import "./page.css";

const Login = () => {

  return (
    <>
      <div className="login-main-div row">
        {/* <div className="form-div col">
          <h3>Login</h3>
          <input type="text" className="inputs" placeholder="Email" />
          <input type="text" className="inputs" placeholder="Name" /><br></br>
          <h6>
            <input type="checkbox" id="check" className="mr-4" />
            <label>Remember me</label><br></br>
          </h6>
          <button>Login</button>
          <p>Sign Up</p>
          <span>Forgot your password</span>
        </div>
        {!isDontShowImage && <div className="col">
          <img width={400} src={LoginMobile} />
        </div>} */}
      </div>
    </>
  )
}

export default Login;