import "./page.css";

const Privacy = () => {

  return (
    <>
      <div className="banner-title">PRIVACY AND POLICY</div>
      <div className="llc-div row">
        <div className="col-md-12">
          <h2>PRIVACY AND POLICY:</h2>
          <p>
            This Privacy Policy explains the types of personal information we collect, how we may share it,
            and how you can control the sharing of your data. At Space Tax Filing, we prioritize the security
            and protection of all our customers' personal information, recognizing the importance of
            safeguarding your privacy.
          </p>
          <p>
            Weare committed to respecting your privacy and understanding the need for proper protection
            and management of your personal information. Our goal is to ensure that your data is handled
            with the utmost care and in compliance with all relevant privacy standards.
          </p>
          <ul className="career-ul">
            <li>
              Ourwebsite may contain links to external sites with their own privacy policies, which we
              do not control. Accessing these sites is at your own risk and not governed by our Privacy
              Policy.
            </li>
            <li>
              Visitors can browse our website without providing personal information. We may collect
              data such as IP addresses for statistical purposes, but users remain anonymous unless
              they voluntarily share personal details.
            </li>
            <li>
              Whenyouregister for our services, we collect and store personal information you
              provide, such as your name, contact details, and data relevant to your tax returns.
              Sharing this information is voluntary, but we cannot provide services without it.
            </li>
            <li>
              Youcanupdate your personal information, including name, password, email, and
              address, at any time, as well as manage your mailing preferences. To request updates or
              removal from our mailing lists, or for assistance, simply email us with your request.
            </li>
            <li>
              Ourwebsite uses cookies to enhance your experience by personalizing content based
              on the areas you visit. While cookies don’t harm your system or store personal
              information, disabling them may affect website functionality.
            </li>
            <li>
              Wemayupdateour Privacy Policy periodically, with changes effective from the "Last
              Updated" date noted. The updated policy will apply to all users, and we encourage
              regular review to stay informed.
            </li>
            <li>
              Byusing our software to prepare and file returns electronically, you consent to sharing
              relevant information with federal and state agencies as required by law and to
              transmitting your tax return(s).
            </li>
            <li>
              Certain states may require paper filing, which involves signing your tax returns by hand
              and submitting them directly to the state's tax authority. We’ll provide copies of your
              returns, a prepaid envelope, and filing instructions.
            </li>
            <li>
              Ourservices are not intended for children under 16, and we do not knowingly collect
              their personal data. If we discover a child is using the services, we will block access and
              promptly delete any associated data.
            </li>
            <li>
              Tohelpus enhance our privacy and security practices, please share your feedback via
              the contact details on our website's "Contact Us" page.
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Privacy;