import "./page.css";

import Services from "../Segments/Services/Services";

import { useState } from "react";


const Individual = () => {

  const [leftLis] = useState([
    "Tax return Preparation – Federal",
    "Resident Returns – 1040EZ, 1040A, 1040",
    "Non - Resident Returns – 1040NR, 1040NR - EZ",
    "Amendment returns – 1040X",
    "Partnership Return – 1065",
    "Business Returns – 1120, 1120 - S & 1120 - C",
    "Trust Return - 1041",
    "Tax return Preparation – State, City & Local"
  ])

  return (
    <>
      <div className="banner-title">Our Services</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <h2>Individual Tax Preparation</h2>
          <h3>We specialize in preparation of:</h3>
          <ul style={{ listStyle: "none" }}>
            {
              leftLis.map(l => {
                return <li>{l}</li>
              })
            }
          </ul>
          <p>
            Filing Individual Income Tax (Federal and State) Backed by a team of experienced professionals from financial field,
            we hold expertise in providing Income Tax services. Our proficient team of experts is well versed in handling various
            taxation needs which includes, progressive, proportional and regressive. Moreover, our experts keep themselves updated
            with prevailing laws and norms to provide the customers with authentic and accurate solution.
          </p>
          <p>
            Preparing your own income tax return can be a task that leaves you with more questions than answers. According to a
            study released by the US Government’s General Accounting Office last year, most taxpayers (77% of 71 million taxpayers)
            believe they benefited from using a professional tax preparer. Whether we like it or not, today’s tax laws are so
            complicated that filing a relatively simple return can be confusing. It is just too easy to overlook deductions and
            credits to which you are entitled. Even if you use a computer software program there’s no substitute for the assistance
            of an experienced tax professional.
          </p>
        </div>
        <div className="col">
          <Services />
        </div>
      </div >
    </>
  )
}

export default Individual;