import "./page.css";

import Services from "../Segments/Services/Services";

const IndianTaxes = () => {

  return (
    <>
      <div className="banner-title">Indian Taxes</div>
      <div className="llc-div row">
        <div className="col-md-8">
          <p>
            Space Tax operates a processing center in India that assists its US tax clients in filing Indian tax returns,
            even if they are required to do so as non-residents or part-year residents. Here are several situations that may
            necessitate filing a tax return in India.
          </p>
          <p>
            A taxpayer receiving wages or salaries in India while residing in the USA during the tax year—this is often
            relevant for Indian citizens assigned to their US employer's location or client sites.
          </p>
          <p>
            A taxpayer earning interest or dividends from any source in India.
          </p>
          <p>
            A taxpayer owning property in India—residential or otherwise—that is rented out and generates rental income during the tax year.
          </p>
          <p>
            A taxpayer who sells property at a profit or loss within the tax year.
          </p>
          <p>
            A taxpayer engaging in stock transactions during the tax year.
          </p>
          <p>
            A taxpayer who is a partner in a partnership in India must file tax returns if they receive any income from that partnership.
          </p>
          <p>
            In these circumstances, filing tax returns in India is necessary to comply with local tax laws.
          </p>
        </div>
        <div className="col">
          <Services />
        </div>
      </div >
    </>
  )
}

export default IndianTaxes;